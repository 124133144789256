<template>
  <section class="container mt-20">
    <div class="list">
      <div @click="n(l)" class="example" v-for="l in list" :key="l">
        <Lottie :name="l" :style="{ position: 'relative', width: '100px', height: '100px' }" />
        <h1>{{ getName(l) }}</h1>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed, notify } from '@/logic/functions'
const folder = ref('')
const illustrations = require.context('@/assets/images/lottie/', true, /^.*\.*$/)
const listWithDublicates = computed(() => illustrations.keys().map(fix).filter(f => onlyFolder({ f, folder: folder.value })))
const list = computed(() => [...new Set(listWithDublicates.value)])

function fix(a) {
  return `${a.replace('./', '').replace('.json', '')}`
}

function n(lottie) {
  notify({ lottie, lottieStyle: { transform: 'scale(1)' }, title: 'Событие', text: 'Пример уведомления', visibility: 0 })
}

function getName(l) {
  return l?.split('/')[l?.split('/')?.length-1] || l
}

function onlyFolder({ f, folder }) {
  if (!folder) return true
  return f.includes(`${folder}/`)
}
</script>

<style lang="sass" scoped>
.buttons
  display: flex
  gap: 20px
  align-items: center
  margin-bottom: 20px

.list
  display: flex
  flex-wrap: wrap
  gap: 30px

.example
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

  h1
    margin-top: 10px
    font-size: 13px
</style>